<template>
  <div class="login">
    <div class="login-box">
      <img src="../assets/login-logo.png" class="login-logo" />
      <div class="login-form">
        <div class="form-top">
          <img src="../assets/form-top.png" />
        </div>
        <div class="login-form-box">
          <div class="login-form-box-item">
            <img src="../assets/user-icon.png" />
            <input type="text" v-model="name" placeholder="请输入您的账号" />
          </div>
          <div class="login-form-box-item">
            <img src="../assets/pwd-icon.png" />
            <input type="password" v-model="pwd" placeholder="请输入您的密码" />
          </div>
          <div class="login-btn" @click="handleLogin">进入系统</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { login } from '../serive/api'
export default {
  data() {
    return {
      name: '',
      pwd: ''
    }
  },
  methods: {
    handleLogin() {
      let params = {
        account: this.name,
        password: this.pwd
      }
      login(params).then(res => {
        console.log(res)
        if (res.data.code === 'OK') {
          localStorage.setItem('token', true)
          localStorage.setItem('username', this.name)
          this.$router.push('/home')
        } else {
          this.$message.error(res.data.msg)
          return
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
.login {
  width: 100%;
  height: 100%;
  min-width: 1200px;
  min-height: 600px;
  background: #fff url(../assets/login-bg.png) no-repeat;
  background-size: 100% auto;
  background-position: center 0;
  &-box {
    width: 1200px;
    margin: 0 auto;
  }
  &-logo {
    width: 393px;
    height: 92px;
  }
  &-form {
    width: 540px;
    background: #fff;
    margin: 100px auto 0;
    padding: 80px 60px 70px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    .form-top {
      width: 475px;
      height: 139px;
      margin: 0 auto;
      img {
        width: 100%;
        height: auto;
      }
    }
    .login-form-box {
      margin-top: 40px;
      &-item {
        display: flex;
        align-items: center;
        margin-bottom: 35px;
        input {
          width: 100%;
          height: 78px;
          line-height: 78px;
          text-indent: 20px;
          color: #333;
          font-size: 24px;
          border: 1px solid #bfbfbf;
          border-left: none;
          outline: none;
          &:focus {
            outline: 1px solid #3488ea;
          }
        }
      }
      .login-btn {
        width: 100%;
        height: 72px;
        line-height: 72px;
        color: #fff;
        font-size: 24px;
        text-align: center;
        background: #3488ea;
        border-radius: 10px;
        margin-top: 58px;
        cursor: pointer;
      }
    }
  }
}
input::-webkit-input-placeholder {
  color: #999;
  font-size: 20px;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #999;
  font-size: 20px;
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #999;
  font-size: 20px;
}
input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #999;
  font-size: 20px;
}
@media screen and (max-width: 1366px) {
  .login-form {
    transform: scale(0.6);
    margin-top: -80px;
    padding: 50px 60px 40px;
  }
}
</style>
